import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { chargingAtom } from '../atoms-types/chargers';
import useWebSocket from '../wsHook';
import ChargingGraph from '../charts-graphs/chargingGraph'
import { Charger, ChargingHandlerProps } from '../atoms-types/chargers';

export const ChargingHandler: React.FC<ChargingHandlerProps> = ({ BuildingID }) => {
  const { messages } = useWebSocket('wss://odp.golf/websocket', ['charger']);
  const setChargingData = useSetAtom(chargingAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['fallbackCharging'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<Charger[]>('https://odp.golf/api/fallback-charging');
      const data = response.data;
      UpdateChargingData(data);
      console.log("all fallback Charging: ", data);
      return data;
    }
  });

function UpdateChargingData(data: Charger[]) {
  if (!data || data.length === 0) {
    console.error("No data available to update charging information");
    return;
  }

  console.log("All fallback Charging data:", data);

  const parsedData = data.reduce((acc, chargingData) => {
    if (!chargingData.BuildingID) return acc;

    acc[chargingData.BuildingID] = {
      BuildingID: chargingData.BuildingID,
      NCharging: chargingData.NCharging,
      NWaiting: chargingData.NWaiting,
      PhA: chargingData.PhA,
      PhB: chargingData.PhB,
      PhC: chargingData.PhC,
      Grid: chargingData.Grid,
      Load: chargingData.Load,
      Solar: chargingData.Solar,
      Timestamp: chargingData.Timestamp,
      CState:
        typeof chargingData.CState === "string"
          ? JSON.parse(chargingData.CState)
          : chargingData.CState,
      CPower:
        typeof chargingData.CPower === "string"
          ? JSON.parse(chargingData.CPower)
          : chargingData.CPower,
      CEnergy:
        typeof chargingData.CEnergy === "string"
          ? JSON.parse(chargingData.CEnergy)
          : chargingData.CEnergy,
    };

    return acc;
  }, {} as Record<string, Charger>);

  setChargingData((prev) => {
    const updatedData = Object.keys(parsedData).reduce((acc, key) => {
      acc[key.toLowerCase()] = parsedData[key];
      return acc;
    }, {} as Record<string, Charger>);

    return { ...prev, ...updatedData };
  });
}


  useEffect(() => {
    messages.forEach((messageObj) => {
      console.log("message received: ", messageObj);
      const { channel, message } = messageObj;

      if (channel === 'charger') {
        try {
          if (message && typeof message === "object" && "BuildingID" in message) {
            console.log('Updating charging data:', message);
            const b = String(message.BuildingID).toLowerCase();
            setChargingData((prev: Record<string, Charger>) => ({
              ...prev,
              [b]: { ...message } as Charger,
            }));
          } else {
            console.error('Invalid charger payload format:', message);
          }
        } catch (error) {
          console.error('Failed to parse message:', error);
        }
      }
    });
  }, [messages, setChargingData]);

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <div>
      {renderLoadingOrError()}
      <ChargingGraph BuildingID = {BuildingID} />
    </div>
  );
};

export default ChargingHandler;


