import { atom } from 'jotai';


export interface Device {
  deviceEUI: string,
  name: string,
  type: string,
  SOC: number,
  TbattLvl: number,
  lastRx: string,
  RSSI: number,
  lastIncidentCode: string,
  carUID: string
}

export interface DeviceMessage {
  action: string;
  param: string;
  data: Device[] | any;
}

export interface DevLocation {
  deviceEUI: string,
  name: string,
  type: string,
  lat: number,
  lng: number,
  area: string | null,
  timestamp: string
}

export const allDeviceAtom = atom<Device[]>([]);

export const deviceLocationAtom = atom<DevLocation[]>([]);

export const allDevLocationAtom = atom<DevLocation[]>([]);

export const devicePathAtom = atom<DevLocation[]>([]);

