import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChargingStationIcon from '@mui/icons-material/EvStation';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import TimelineIcon from '@mui/icons-material/Timeline';
import PlaceIcon from '@mui/icons-material/Place';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import PoolIcon from '@mui/icons-material/Pool';
import FoundationIcon from '@mui/icons-material/Foundation';
import BugReportIcon from '@mui/icons-material/BugReport';
import { type Navigation } from '@toolpad/core/AppProvider';

const Base = [
  {
    segment: 'charging',
    title: 'Charging',
    icon: <ChargingStationIcon />,
    children: [
      {
        segment: 'pool',
        title: 'Poolhouse',
        icon: <PoolIcon />,
      },
      {
        segment: 'pavilion',
        title: 'Pavilion',
        icon: <FoundationIcon />,
      },
      {
        segment: 'odptest',
        title: 'Test',
        icon: <BugReportIcon />,
      }

    ]
  },
  {
    segment: 'location',
    title: 'Location',
    icon: <PlaceIcon />,
    children: [
      {
        segment: 'map',
        title: 'Map',
        icon: <MapIcon />,
      },
      {
        segment: 'trackers',
        title: 'Trackers',
        icon: <ListIcon />,
      },
    ],
  }
]

export const StaffNav: Navigation = [
    {
    kind: 'header',
    title: 'Main items',
  },
  ...Base
]

export const AdminNav: Navigation = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  ...Base,
  {
    segment: 'environment',
    title: 'Environment',
    icon: <DeviceThermostatIcon />,
  },
  {
    kind: 'divider',
  },
  {
    kind: 'header',
    title: 'Facilities',
  },
  {
    segment: 'building',
    title: 'Building',
    icon: <TimelineIcon />,
  },
]