import { atom } from 'jotai';

export interface Charger {
  BuildingID: string;
  Timestamp: string;
  NCharging: number;
  NWaiting: number;
  PhA: number;
  PhB: number;
  PhC: number;
  Grid: number;
  Load: number;
  Solar: number;
  CState: number[];
  CPower: number[];
  CEnergy: number[];
}
export interface ChargingHandlerProps {
  BuildingID: string | undefined;
}

export const chargingAtom = atom<{ [BuildingID: string]: Charger }>({});

export const toggleCharging = atom<'CPower' | 'CEnergy'>('CPower');